import './App.css';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import React, { useEffect, useState } from 'react';
import { Button, Divider, ListItemButton, Paper, Typography, Container } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import siriVictory from './images/siri_victory.jpg'
import VictorySpeechModal from './VictorySpeechModal.js'
import Champions from './Champions.js';

function AppContent() {

    const [open, setOpen] = React.useState(false);
    const [speech, setSpeech] = React.useState('');
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Paper>
            <Typography variant='h3'>Pickem Rules</Typography>
            <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                    Pick one NFL team each week to win their game. Once you have picked a team you can't choose them again. Whoever has the most correct picks at the end of the regular season is the champion.
                    <List dense={true} sx={{
                        listStyleType: 'disc',
                        pl: 2,
                        '& .MuiListItem-root': {
                            display: 'list-item',
                        },
                    }}>
                        <Grid container xs={12} columnSpacing={2}>
                            <Grid item xs={6}>
                                <ListItem>
                                    Must pick team before their game's listed start time online
                                </ListItem>
                            </Grid>
                            <Grid item xs={6}>
                                <ListItem>
                                    Only wins count, TIES = LOSS
                                </ListItem>
                            </Grid>
                            <Grid item xs={6}>
                                <ListItem>
                                    Tie breaker is the worst combined record of picked teams
                                </ListItem>
                            </Grid>
                            <Grid item xs={6}>
                                <ListItem>
                                    We use the NFL's week grouping, so Thursday, Sunday, Monday are on the same week. <a href='https://www.nfl.com/schedules/'>Here is the offical schedule</a>
                                </ListItem>
                            </Grid>
                            <Grid item xs={6}>
                                <ListItem>
                                    If you double pick a team, you can change until the week is over. If you fail to change to a valid pick it will be counted as a loss.
                                </ListItem>
                            </Grid>
                        </Grid>
                    </List>
                </Grid>
                
                <Grid item xs={12}>
                    <a href="https://docs.google.com/spreadsheets/d/1WHWXEtrATFIcQN6aweIn4wfvkx5NtxI40inu41fybhI/edit?usp=sharing">
                        <Button variant="contained" size="large">Go to NFL Pick'em</Button>
                    </a>
                </Grid>
            </Grid>
            <br/>
            <Divider/>
            <br/>
            <Champions />
        </Paper>
    );
}

export default AppContent;
