import './App.css';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import React, { useEffect, useState } from 'react';
import { Button, Divider, ListItemButton, Paper, Typography, Container } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import siriVictory from './images/siri_victory.jpg'
import VictorySpeechModal from './VictorySpeechModal.js'

function Champions() {

    const [open, setOpen] = React.useState(false);
    const [speech, setSpeech] = React.useState('');
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h3'>HALL OF CHAMPIONS</Typography>
                </Grid>

                <Grid item xs={12}>
                    <List>
                        <ListItem alignItems="center">
                            <ListItemAvatar>
                                <Avatar alt="Siri" src={siriVictory} sx={{ width: 124, height: 124 }} />
                            </ListItemAvatar>
                            <ListItemText align="center">
                                2024 Paris Olympics Champion
                            </ListItemText>
                            <ListItemButton>
                                <Button variant="contained"
                                    onClick={() => {
                                        handleOpen()
                                        setSpeech('SUCK IT LOSERS. GO AUSTRALIA.')
                                    }}

                                >Victory Speech</Button>
                            </ListItemButton>
                        </ListItem>
                    </List>

                </Grid>
            </Grid>

            <VictorySpeechModal
                open={open}
                handleClose={handleClose}
                speech={speech}
            />
            </div>
    );
}

export default Champions;
